<template>
  <v-form ref="formRef" class="form-container">
    <template v-if="!hideTextFields">
      <v-row>
        <v-text-field
          v-model="user.lastName"
          class="col-5"
          outlined
          clearable
          type="text"
          :placeholder="$t('lastName')"
          :label="$t('lastName')"
          color="primary"
          :rules="requiredFieldRules"
        />
        <v-text-field
          v-model="user.firstName"
          class="col-5 offset-2"
          outlined
          clearable
          type="text"
          :placeholder="$t('firstName')"
          :label="$t('firstName')"
          color="primary"
          :rules="requiredFieldRules"
        />
      </v-row>

      <v-row>
        <v-text-field
          v-model="user.nationalities"
          outlined
          clearable
          type="text"
          :placeholder="$t('nationalities')"
          :label="$t('nationalities')"
          color="primary"
          :rules="[requiredFieldRule, nationalityRule]"
          @input="nationalityError = ''"
        />
      </v-row>

      <v-row>
        <v-col sm="5">
          <label class="v-label v-label--active theme--light"
            >{{ $t('dateOfBirth') }}
          </label>
          <v-text-field
            v-model="user.birthDate"
            :rules="requiredFieldRules"
            readonly
          ></v-text-field>
        </v-col>

        <v-col sm="7">
          <v-date-picker
            v-model="user.birthDate"
            clearable
            prepend-icon="mdi-birthDate"
            class="pl-5"
            :rules="requiredFieldRules"
            :show-current="false"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-select
          v-model="user.birthPlace"
          :items="countries"
          item-text="name"
          item-value="id"
          :label="$t('placeOfBirth')"
          :placeholder="$t('placeOfBirth')"
          dense
          outlined
          clearable
          :rules="requiredFieldRules"
          class="col-5"
          menu-props="auto"
        />
      </v-row>

      <v-row>
        <v-text-field
          v-model="user.addressStreet"
          class="col-5"
          outlined
          clearable
          type="text"
          :placeholder="$t('streetAddress')"
          :label="$t('streetAddress')"
          color="primary"
          :rules="[requiredFieldRule, streetRule]"
          @input="streetError = ''"
        />
        <v-text-field
          v-model="user.postalCode"
          class="col-5 offset-2"
          outlined
          clearable
          type="number"
          :placeholder="$t('postalCode')"
          :label="$t('postalCode')"
          color="primary"
          :rules="[requiredFieldRule, postalCodeRule]"
          @input="postalCodeError = ''"
        />
      </v-row>

      <v-row>
        <v-select
          v-model="user.country"
          :items="countries"
          item-text="name"
          item-value="id"
          :label="$t('country')"
          :placeholder="$t('country')"
          dense
          outlined
          clearable
          :rules="requiredFieldRules"
          class="col-5"
          menu-props="auto"
        />
        <v-text-field
          v-model="user.city"
          class="col-5 offset-2"
          dense
          outlined
          clearable
          :placeholder="$t('city')"
          :label="$t('city')"
          color="primary"
          :rules="[requiredFieldRule, cityRule]"
          @input="cityError = ''"
        />
      </v-row>

      <v-row>
        <v-text-field
          v-model="user.phoneCode"
          class="col-2"
          outlined
          clearable
          placeholder="+49"
          :label="$t('phoneCode')"
          color="primary"
          :rules="[requiredFieldRule, phoneCodeRule]"
          @input="phoneCodeError = ''"
        />

        <v-text-field
          v-model="user.phoneNumber"
          class="col-5"
          outlined
          clearable
          placeholder="30901820"
          :label="$t('phoneNumber')"
          color="primary"
          :rules="[requiredFieldRule, phoneNumberRule]"
          @input="phoneNumberError = ''"
        />
      </v-row>
      <v-row>
        <v-text-field
          v-model="user.email"
          class="col-5"
          outlined
          clearable
          type="email"
          placeholder="email@example.com"
          :label="$t('email')"
          color="primary"
          :rules="[requiredFieldRule, emailRule]"
          @input="emailError = ''"
        />
      </v-row>
      <v-row>
        <v-text-field
          v-if="formWithPassword"
          v-model="user.password"
          class="col-5 text-capitalize"
          outlined
          clearable
          type="password"
          :label="$t('password')"
          color="primary"
          :rules="requiredFieldRules"
        />
      </v-row>
    </template>

    <template v-if="!hideFileUploader">
      <hr class="my-md-3" />
      <div class="text-h6 left-text-padded text-capitalize">
        {{ passportUploaderTitle }}
      </div>
      <div class="text-subtitle-2 left-text-padded">
        {{ passportUploaderDetails }}
      </div>
      <br />
      <v-file-input
        v-model="user.passport"
        :placeholder="passportUploaderPlaceholder"
        :label="$t('passport')"
        prepend-icon="mdi-paperclip"
        :rules="requiredFieldRules"
      />
    </template>
  </v-form>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'PersonalRegistrationForm',
  props: {
    passportUploaderTitle: {
      type: String,
      default: ''
    },
    passportUploaderDetails: {
      type: String,
      default: ''
    },
    passportUploaderPlaceholder: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      default() {
        return {}
      }
    },
    formWithPassword: { default: false, type: Boolean },
    hideFileUploader: { default: false, type: Boolean },
    hideTextFields: { default: false, type: Boolean },
    countries: {
      type: Array,
      default() {
        return []
      }
    },
    errors: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data: function() {
    return {
      requiredFieldRules: [v => !!v || this.$i18n.t('requiredError')],
      streetError: '',
      postalCodeError: '',
      phoneCodeError: '',
      phoneNumberError: '',
      emailError: '',
      nationalityError: '',
      cityError: ''
    }
  },
  watch: {
    errors: function() {
      this.streetError =
        'address__street' in this.errors ? this.errors['address__street'] : ''
      this.postalCodeError =
        'address__postal_code' in this.errors
          ? this.errors['address__postal_code']
          : ''
      this.phoneCodeError =
        'phone_code' in this.errors ? this.errors['phone_code'] : ''
      this.phoneNumberError =
        'phone_number' in this.errors ? this.errors['phone_number'] : ''
      this.emailError = 'email' in this.errors ? this.errors['email'] : ''
      this.nationalityError =
        'nationalities' in this.errors ? this.errors['nationalities'] : ''
      this.cityError = 'city' in this.errors ? this.errors['city'] : ''
      return this.$refs.formRef.validate()
    }
  },
  methods: {
    validate() {
      return this.$refs.formRef.validate()
    },
    requiredFieldRule(value) {
      return !value ? this.$t('requiredError') : true
    },
    streetRule() {
      return this.streetError || true
    },
    postalCodeRule() {
      return this.postalCodeError || true
    },
    phoneCodeRule() {
      return this.phoneCodeError || true
    },
    phoneNumberRule() {
      return this.phoneNumberError || true
    },
    emailRule(value) {
      if (!/.+@.+\..+/.test(value)) return this.$i18n.t('emailValidation')
      return this.emailError || true
    },
    nationalityRule() {
      return this.nationalityError || true
    },
    cityRule() {
      return this.cityError || true
    }
  }
})
</script>

<style lang="scss">
.form-container {
  padding: 0 4%;
}
</style>
